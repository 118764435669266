.utility-nav__cart-count,
.utility-nav__cart-button {
  border-left: none;
}

.utility-nav__cart {
  @media #{$medium-up} {
    left: 15px;
  }
  @media #{$xlarge-up} {
    left: 45px;
  }
}

.search-product__wishlist {
  display: none;
}

.footer-signup-email {
  margin-left: 25%;
  @media #{$small-only} {
    margin-left: 0;
  }
}

.quickshop-inline__wishlist {
  display: none;
}

.elc-product-full .elc-product-display-name-link,
.elc-product-full .elc-product-subheader {
  font-family: $optimalight;
}

.p-w-r [aria-modal=true] .pr-flag-review-input[type=radio] {
  position: static;
  box-shadow: none;
}

#pr-reviewdisplay .p-w-r .pr-review-display .pr-review .pr-rd-footer {
  box-shadow: none;
  .pr-helpful-voting {
    width: max-content;
  }
  .pr-rd-flag-review-container {
    top: 0;
    white-space: nowrap;
    div {
      box-shadow: none;
    }
  }
}

#pr-reviewdisplay #pr-review-snapshot .pr-review-snapshot-block-container .pr-review-snapshot-block-histogram {
  margin-top: 5%;
}

#pr-reviewdisplay .p-w-r .pr-review-display .pr-rd-main-header .pr-rd-review-header-sorts .pr-rd-sort-group {
  display: inline-flex;
  align-items: baseline;
  width: fit-content;
  &:before {
    top: 0;
  }
}
