.trust_mark_footer {
  border-top: 1px solid #A4A4AB;
  padding-top: 10px;
  padding-bottom: 10px;
  .trust_mark_footer__link {
    width: 128px;
    height: 145px;
    background-image: url('/media/images/global/lo2-white.png');
    background-repeat: no-repeat;
    text-indent: -9999px;
    display: block;
  }
}
