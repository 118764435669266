// EsteeLauder VN Fonts
/* APACVEGA-2680 */

// AkzidenzGrotesk VN
@font-face {
  font-family: '#{$akzidenz}';
  src: url('#{$netstorage-font-path}AkzidenzGrotesk/akzidenz-grotesk-w1g-regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: '#{$akzidenz}';
  src: url('#{$netstorage-font-path}AkzidenzGrotesk/akzidenz-grotesk-w1g-bold.woff2') format('woff2');
  font-weight: bold;
  font-display: swap;
}

// Optima VN
// Resolving to Optima Display Roman
@font-face {
  font-family: '#{$optimalight}';
  src: url('#{$netstorage-font-path}OptimaDisplay/OptimaDisplay-Roman.woff2') format('woff2');
  font-display: swap;
}
// Arsenal
@font-face {
  font-family: '#{$arsenal}';
  src: url('#{$netstorage-font-path}Arsenal/arsenal-regular.woff2') format('woff2');
  font-display: swap;
}

#content {
  .blog-article blockquote,
  .blog-article__header,
  .blog-article__teaser,
  .discover-block__header,
  .discover-block__teaser {
    font-family: $optimalight;
  }
  .body_copy {
    font-family: $akzidenz;
  }
}
