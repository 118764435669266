///
/// @file drupal_modules/esteelauder_endeca/_mobile_page_search.scss
///
/// \brief styles for the mobile search page
///
/// used in template:
/// esteelauder_endeca/templates/endeca_search_mobile_v1/
/// and various theme based endeca tpl files
///

.el-mobile-search__product-results {
  .button--light {
    line-height: 2;
  }
}